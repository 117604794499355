import './App.css';
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
         <div className="p-10 bg-gray-50 h-screen">
            <div className="flex items-center justify-center py-12">
                <div className="px-10 bg-white border border-black rounded-md flex items-center justify-center mx-4 md:w-2/3 ">
                    <div className="flex flex-col items-center py-16 ">
                        <img className="px-4 hidden md:block" src="https://static.vecteezy.com/system/resources/previews/006/549/647/original/404-landing-page-free-vector.jpg" alt="" />
                        <img className="md:hidden" src="https://static.vecteezy.com/system/resources/previews/006/549/647/original/404-landing-page-free-vector.jpg" alt="" />
                        <h1 className="px-4 pt-8 pb-4 text-center text-4xl font-bold leading-10 text-gray-800">We've Moved! </h1>
                        <p className="px-4 pb-10 mt-5 text-base leading-5 text-center text-gray-600">Head over to greenscores.xyz to browse.</p>
                        
                        <Link to="https://greenscores.xyz">
                          <button className="p-3 w-full border rounded-md text-white text-base bg-black focus:outline-none focus:ring-1 focus:ring-opacity-50 focus:ring-green-300">go to greenscores.xyz</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
